import { get, put } from '@/helpers/request'
import { toSnakeCase } from '@/helpers/case-style'
import deepMap from '@/helpers/deep-object-mapper'
import JSONAPIClient from '@/helpers/jsonapi-client'

export default {
  getApplicants: async (params) => {
    const response = await get('/hunters/applicants', deepMap(params, toSnakeCase))

    return JSONAPIClient.parse(response.data)
  },

  approveApplicant: ({ id, ...candidate }) => {
    return put(`/hunters/applicants/${id}/approve`, deepMap(candidate, toSnakeCase))
  },

  rejectApplicant: (id) => {
    return put(`/hunters/applicants/${id}/reject`)
  }
}
