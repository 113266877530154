<template>
  <app-modal @close="$emit('refuse')">
    <template #header>
      <h5>{{ $t(`${langPath}.title`) }}</h5>
    </template>
    <main class="move-candidate-modal__body">
      <p class="move-candidate-modal__body__paragraph">
        {{ $t(`${langPath}.firstParagraph`) }}
      </p>
      <p class="move-candidate-modal__body__paragraph">
        {{ $t(`${langPath}.secondParagraph`) }}
      </p>
    </main>
    <template #footer>
      <footer class="move-candidate-modal__footer">
        <ev-button
          variant="tertiary"
          color="blue"
          data-test="refuse-button"
          @click="$emit('refuse')"
        >
          {{ $t(`${langPath}.refuse`) }}
        </ev-button>

        <ev-button
          data-test="accept-button"
          @click="$emit('accept')"
        >
          {{ $t(`${langPath}.accept`) }}
        </ev-button>
      </footer>
    </template>
  </app-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

export default {
  name: 'MoveCandidateModal',
  components: {
    EvButton
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.move-candidate-modal {
  &__body {
    padding: $base*6 0;

    &__paragraph {
      @extend %body-text1;

      margin-bottom: $base * 5;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
