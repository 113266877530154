<template>
  <skeleton class="workflow-stage-skeleton" />
</template>

<script>
import Skeleton from './Skeleton'

export default {
  name: 'WorkflowStageSkeleton',

  components: {
    Skeleton
  }
}
</script>

<style lang="scss">
.workflow-stage-skeleton {
  height: 50vh;
  margin: 0 $base*2;
  width: $base*75;

  &:first-child { margin-left: 0; }
}
</style>
