import { get } from '@/helpers/request'
import jsonapiClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  async getSuggestedCandidates (positionId, limit = 10) {
    const path = `${baseUrl}/ats/positions/${positionId}/suggested_candidates?quantity=${limit}`

    const response = await get(path)

    return jsonapiClient.parse(response.data)
  }
}
