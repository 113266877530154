const defaultTabs = ['interview', 'comments', 'proposal']

export default {
  screened: {
    route: 'ApplicantInterview',
    tabs: defaultTabs
  },
  interview: {
    route: 'ApplicantInterview',
    tabs: defaultTabs
  },
  offer: {
    route: 'ApplicantProposal',
    tabs: defaultTabs
  },
  hired: {
    route: 'ApplicantProposal',
    tabs: defaultTabs
  },
  refused: {
    route: 'ApplicantRejected',
    tabs: []
  },
  default: {
    pendingState: 'awaiting_candidate_response',
    refusedState: 'applicant_refused',
    route: 'ApplicantInterview'
  }
}
