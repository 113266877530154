<template>
  <workflow-stage-skeleton v-if="isStageLoading" />
  <section
    v-else
    class="workflow-stage"
  >
    <header class="workflow-stage__header">
      <workflow-stage-title
        class="workflow-stage__header-title"
        :title="stage.label"
        :count="count"
        :editable="stageEditable"
        @update-stage-label="handleUpdateTitle"
      />
      <app-dropdown-menu
        v-if="!defaultStage"
        has-shadow
        class="workflow-stage__dropdown"
      >
        <template slot="title">
          <icn-more />
        </template>
        <app-item-menu
          data-test="delete-menu"
          @click="handleDelete"
        >
          <p>{{ $t(`${langPath}.delete`) }}</p>
        </app-item-menu>
      </app-dropdown-menu>
    </header>
    <draggable
      v-model="applicantsList"
      v-bind="dragOptions"
      data-test="workflow-stage__items"
      class="workflow-stage__items"
      group="applicants"
      :component-data="{ stage }"
      @start="isDragging = true"
      @end="isDragging = false"
    >
      <workflow-stage-card
        v-for="applicant in applicantsList"
        :key="applicant.id"
        :applicant="applicant"
        :candidate="applicant.candidate"
        @click="openApplicant(applicant)"
      />
    </draggable>
    <workflow-stage-delete-modal
      v-if="showDeleteModal"
      @cancel="showDeleteModal = false"
      @ok="confirmDelete"
    />
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import { IcnMore, TheNotificationCardList } from '@revelotech/everest'
import WorkflowStageCard from './WorkflowStageCard'
import WorkflowStageTitle from './WorkflowStageTitle'
import WorkflowStageSkeleton from '@/components/Skeletons/WorkflowStageSkeleton'
import WorkflowStageDeleteModal
  from '@/pages/WorkflowBoard/components/WorkflowStageDeleteModal'
import { applicantRoute } from '@/helpers/stages'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, FILTERS, STAGES } from '@/store/namespaces'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const filtersHelper = createNamespacedHelpers(FILTERS)
const stagesHelper = createNamespacedHelpers(STAGES)

export default {
  name: 'WorkflowStage',
  components: {
    draggable,
    WorkflowStageTitle,
    WorkflowStageCard,
    WorkflowStageDeleteModal,
    WorkflowStageSkeleton,
    IcnMore
  },
  props: {
    stage: {
      type: Object,
      required: true
    },
    positionId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isDragging: false,
      isStageLoading: true,
      langPath: __langpath,
      showDeleteModal: false
    }
  },
  computed: {
    ...filtersHelper.mapState(['filter', 'employerFilter']),
    ...currentUserHelper.mapState(['currentUser']),
    applicants () {
      const applicants = this.stage?.applicants

      return applicants.sort(this.sortByNewCandidateAndApplicantIndex)
    },
    applicantsList: {
      get () {
        return this.applicants
      },
      set (value) {
        const stageInfo = {
          stageId: this.stage.id,
          applicantsOrder: value.map((applicant) => applicant.id)
        }
        if (value.length > this.applicants.length) {
          const newElement = value.find(
            (element) => !this.applicants.includes(element)
          )
          this.$emit('added', {
            ...newElement,
            ...stageInfo
          })
        } else if (value.length < this.applicants.length) {
          const removedElement = this.applicants.find(
            (element) => !value.includes(element)
          )
          this.$emit('removed', {
            ...removedElement,
            ...stageInfo
          })
        } else {
          this.updateOrder(stageInfo)
        }
      }
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'applicants',
        chosenClass: 'workflow-card--dragging',
        dragClass: 'workflow-card--dragging',
        ghostClass: 'workflow-card--ghost',
        scrollSensitivity: 300,
        forceFallback: true,
        scrollSpeed: 700
      }
    },
    count () { return this.applicants.length },
    defaultStage () { return this.stage.default },
    stageEditable () { return !['refused', 'hired'].includes(this.stage.kind) }
  },
  watch: {
    filter: {
      immediate: true,
      async handler () {
        const employerId = {
          all_candidates: null,
          my_candidates: this.currentUser?.id,
          candidates_from: this.employerFilter?.id
        }
        this.isStageLoading = true
        await this.getStage({
          positionId: this.positionId,
          stageId: this.stage.id,
          employerId: employerId[this.filter]
        })
        this.isStageLoading = false
      }
    }
  },
  methods: {
    ...stagesHelper.mapActions(
      [
        'getStage',
        'deleteStage',
        'updateStage',
        'updateOrder',
        'clearNotifications'
      ]
    ),
    handleDelete (event) {
      if (this.count) {
        return TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.notificationProhibited`),
          modifier: 'error'
        })
      }
      this.showDeleteModal = true
    },
    handleUpdateTitle (title) {
      this.updateStage({ id: this.stage.id, label: title })
    },
    async confirmDelete () {
      const successMessage = this.$t(`${this.langPath}.notificationSuccess`)
      const exceptionMessage = this.$t(`${this.langPath}.notificationError`)
      try {
        await this.deleteStage(this.stage.id)
        this.showDeleteModal = false
        TheNotificationCardList.createNotification({
          message: successMessage,
          modifier: 'success'
        })
      } catch {
        TheNotificationCardList.createNotification({
          message: exceptionMessage,
          modifier: 'error'
        })
      }
    },
    openApplicant (applicant) {
      if (applicant.notificationCount) {
        this.clearNotifications({
          stageId: this.stage.id,
          applicantId: applicant.id
        })
      }
      this.$router.push({
        name: applicantRoute(applicant.currentState, this.stage.kind),
        params: { applicantId: applicant.id }
      })
    },
    sortByNewCandidateAndApplicantIndex (app1, app2) {
      if (app1.newCandidate === app2.newCandidate) {
        if (app1.applicantIndex === app2.applicantIndex) {
          return new Date(app2.createdAt) - new Date(app1.createdAt)
        } else {
          return app1.applicantIndex - app2.applicantIndex
        }
      } else {
        return (app1.newCandidate) ? -1 : 1
      }
    }
  }
}
</script>

<style lang="scss">
.workflow-stage {
  align-self: stretch;
  background: $gray5;
  border-radius: $base;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 $base*2;
  min-height: 50vh;
  min-width: 300px;
  overflow: visible;
  width: $base*75;

  &:first-child { margin-left: 0; }

  &__header {
    align-items: center;
    background: $gray5;
    display: flex;
    justify-content: flex-end;
    padding: $base 0 $base $base*3;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;

    &-title { flex-grow: 1; }

    &:hover { cursor: grab; }
  }

  &__dropdown {
    background-color: inherit;
    z-index: 10;
  }

  &__name {
    @extend %bold;
    @extend %body-text2;

    margin: 0 $base;
    text-transform: uppercase;
    z-index: 6;
  }

  &__items {
    flex-grow: 1;
    padding: 0 $base*2;
    z-index: -1;
  }

  &--ghost { opacity: 0.4; }
}
</style>
