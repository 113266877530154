<template>
  <div
    v-if="isPresentableState"
    class="workflow-card__current-state"
    data-test="current-status"
    :class="`workflow-card__current-state--${currentStateKebabCase}`"
  >
    <ev-icon
      v-if="iconName"
      class="workflow-card__current-state-icon"
      data-test="icon"
      :name="iconName"
      :color="iconColor"
    />
    <p
      :data-test="`current-state__${currentStateKebabCase}`"
      class="workflow-card__current-state-text"
      :class="{ 'workflow-card__current-state-text--big': modalFooter }"
    >
      {{ $t(currentStateLangPath) }}
      {{ displayNextInterviewDate }}
      {{ candidateAcceptedAtText }}
      {{ waitingCandidateResponseSinceText }}
    </p>
  </div>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import { toCamelCase, toKebabCase } from '@/helpers/case-style'

export default {
  name: 'StageCardCurrentState',
  components: {
    EvIcon
  },
  props: {
    nextInterview: {
      type: Object,
      default: () => {},
      required: false
    },
    acceptedAt: {
      type: String,
      default: '',
      required: false
    },
    invitedAt: {
      type: String,
      default: '',
      required: false
    },
    currentState: {
      type: String,
      default: '',
      required: false
    },
    modalFooter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      langPath: __langpath,
      currentStateIcons: {
        'awaiting-candidate-response': { icon: 'fast-mail', color: 'gray60' },
        'candidate-accepted': { icon: 'check', color: 'success' },
        'next-interview-scheduled': { icon: 'schedule', color: 'success' },
        'applicant-refused': { icon: 'ban', color: 'red-light' },
        'refused-proposal': { icon: 'ban', color: 'red-light' },
        'awaiting-proposal': { icon: 'fast-mail', color: 'gray60' },
        'accepted-proposal': { icon: 'star', color: 'success' },
        'company-rejected': { icon: 'ban', color: 'red-light' },
        'next-interview-cancelled': { icon: 'exclamation-triangle', color: 'red-light' }
      }
    }
  },
  computed: {
    currentStateLangPath () {
      return `${this.langPath}.${toCamelCase(this.currentState)}`
    },
    currentStateKebabCase () {
      return toKebabCase(this.currentState)
    },
    displayNextInterviewDate () {
      if (this.currentState === 'next_interview_scheduled') {
        return `: ${this.$moment(this.nextInterview.scheduledStartAt)
          .format('dddd, DD/MM - LT')}`
      } else {
        return ''
      }
    },
    candidateAcceptedAtText () {
      if (this.currentState === 'candidate_accepted') {
        return ` ${this.$moment(this.acceptedAt).fromNow()}`
      } else {
        return ''
      }
    },
    waitingCandidateResponseSinceText () {
      if (this.currentState === 'awaiting_candidate_response') {
        return ` ${this.$moment(this.invitedAt).fromNow()}`
      } else {
        return ''
      }
    },
    presentableStates () {
      return Object.keys(this.currentStateIcons)
    },
    isPresentableState () {
      return this.presentableStates.includes(this.currentStateKebabCase)
    },
    iconName () {
      return this.currentStateIcons[this.currentStateKebabCase]?.icon
    },
    iconColor () {
      return this.currentStateIcons[this.currentStateKebabCase]?.color
    }
  }
}
</script>

<style lang="scss" scoped>
  .workflow-card {
    &__current-state {
      align-items: center;
      background-color: $gray5;
      color: $gray60;
      display: flex;
      padding: 0 5*$base;

      &-icon {
        flex: 0 0 auto;
        margin-right: $base;
        transform: scale(0.6);
      }

      &-text {
        @extend %caption-bold;

        text-transform: uppercase;

        &--big { font-size: $base*3; }
      }

      &--next-interview-scheduled,
      &--accepted-proposal,
      &--candidate-accepted {
        background-color: rgba($success, 0.1); /* copied from everest2 */
        color: $success;
      }

      &--next-interview-cancelled,
      &--refused-proposal,
      &--applicant-refused,
      &--company-rejected {
        background-color: rgba($red-light, 0.1); /* copied from everest2 */
        color: $red-light;
      }
    }
  }
</style>
