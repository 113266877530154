<template>
  <section class="workflow-create-stage">
    <section
      v-if="formVisible"
      data-test="create-stage-form"
      class="workflow-create-stage__form"
      :class="{ 'workflow-create-stage__form--active': formVisible }"
    >
      <app-text-input
        v-model="title"
        v-validate="'required'"
        name="stage-title"
        :data-vv-as="$t(`${langPath}.titleName`)"
        data-test="title-input"
        :placeholder="$t(`${langPath}.titlePlaceholder`)"
      />
      <ev-button
        data-test="cancel-button"
        class="workflow-create-stage__step-button"
        variant="tertiary"
        color="blue"
        size="small"
        @click="cancelAdd"
      >
        {{ $t(`${langPath}.cancelButton`) }}
      </ev-button>

      <ev-button
        data-test="submit-button"
        class="workflow-create-stage__step-button"
        size="small"
        :disabled="disabled"
        @click="nextStep"
      >
        {{ $t(`${langPath}.nextButton`) }}
      </ev-button>
    </section>
    <ev-button
      v-else
      data-ga-id="clicked_add_stage_button"
      data-test="stage-add-button"
      :disabled="stagesCount >= maxNumberOfStages"
      @click="formVisible = true"
    >
      {{ $t(`${langPath}.nextButton`) }}
    </ev-button>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

import { createNamespacedHelpers } from 'vuex'
import { STAGES } from '@/store/namespaces'

import { maxNumberOfStages } from '@/configs/constants'

const stagesHelper = createNamespacedHelpers(STAGES)

export default {
  name: 'WorkflowCreateStage',
  components: {
    EvButton
  },
  props: {
    stagesCount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      disabled: false,
      formVisible: false,
      langPath: __langpath,
      maxNumberOfStages,
      title: ''
    }
  },
  methods: {
    ...stagesHelper.mapActions(['createStage']),
    cancelAdd () {
      this.formVisible = false
    },
    async nextStep () {
      const titleValid = await this.$validator.validate('stage-title')
      if (!titleValid) return

      this.submitStage()
    },
    submitStage () {
      this.createStage({
        label: this.title,
        kind: 'screened',
        positionId: this.$route.params.positionId
      }).then(() => {
        this.formVisible = false
        this.disabled = false
      }).catch(_e => {
        this.disabled = false
      })
    }
  }
}
</script>

<style lang="scss">
$createForm: 75*$base;

.workflow-create-stage {
  align-self: flex-start;
  height: auto;
  width: $createForm;

  &__add-button {
    height: 8*$base;
    padding: 0;
    width: 8*$base;
  }

  &__form {
    background: $gray5;
    border-radius: $base;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: flex-end;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top center;
    transition: 0;
    width: $createForm;

    &--active {
      padding: $base*3;
      transform: scaleY(1);
      transition: 0.2s linear;
    }
  }

  &__step-button {
    margin: 3*$base 0;
  }

  &__title {
    @extend %bold;
    @extend %body-text2;

    margin: 2*$base 0;
    text-transform: uppercase;
    width: 100%;
  }
}
</style>
