<template>
  <app-modal @close="$emit('cancel')">
    <template #header>
      <h5>{{ $t(`${langPath}.title`) }}</h5>
    </template>
    <main class="workflow-stage-delete-modal__body">
      <p>{{ $t(`${langPath}.body`) }}</p>
    </main>
    <template #footer>
      <footer class="workflow-stage-delete-modal__footer">
        <ev-button
          variant="tertiary"
          color="blue"
          data-test="cancel-button"
          @click="$emit('cancel')"
        >
          {{ $t(`${langPath}.cancel`) }}
        </ev-button>

        <ev-button
          data-test="ok-button"
          @click="$emit('ok')"
        >
          {{ $t(`${langPath}.confirm`) }}
        </ev-button>
      </footer>
    </template>
  </app-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

export default {
  name: 'WorkflowStageDeleteModal',
  components: {
    EvButton
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.workflow-stage-delete-modal {
  &__body {
    padding: $base*6 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
