<template>
  <section class="workflow-card" data-test="workflow-card">
    <article
      ref="card"
      class="workflow-card__body"
      :class="{ 'workflow-card--notification': isNewCandidate || isUpdate }"
      @click="$emit('click')"
    >
      <aside class="workflow-card__avatar">
        <figure
          class="workflow-card__picture"
          data-test="candidate__avatar"
        >
          <img
            v-if="candidate.avatar"
            class="workflow-card__picture-img"
            :alt="candidate.name"
            :src="candidate.avatar"
          >
          <span
            v-else
            class="workflow-card__picture-initials"
            data-test="avatar_initials"
          >
            {{ candidateInitials }}
          </span>
        </figure>
        <ev-badge
          v-if="isNewCandidate"
          variant="green"
          class="workflow-card__new-label"
          data-test="new-badge"
          :title="$t(`${langPath}.new`)"
        />
      </aside>
      <main class="workflow-card__infos">
        <p class="workflow-card__infos-name">
          {{ candidate.name }}
          <span
            v-if="isUpdate"
            class="workflow-card__infos-update"
            data-test="update-mark"
          />
        </p>
        <source-badge
          v-if="candidate.source"
          class="workflow-card__source-badge"
          data-test="source-badge"
          :source="candidate.source"
          :source-info="candidate.sourceInfo"
        />
        <p
          v-else
          class="workflow-card__infos-job-title"
          data-test="job-title"
        >
          {{ candidate.jobTitle }}
        </p>
      </main>
    </article>
    <section class="workflow-card__footer">
      <stage-card-current-state
        v-if="applicant"
        class="workflow-card__infos-current-state"
        :invited-at="applicant.createdAt"
        :accepted-at="applicant.candidateAcceptedAt"
        :current-state="applicant.currentState"
        :next-interview="applicant.nextInterview"
      />
    </section>
  </section>
</template>

<script>
import StageCardCurrentState from './StageCardCurrentState'
import SourceBadge from '@/components/Candidates/SourceBadge'
import EvBadge from '@revelotech/everestV2/EvBadge'

export default {
  name: 'WorkflowStageCard',
  components: {
    EvBadge,
    StageCardCurrentState,
    SourceBadge
  },
  props: {
    applicant: {
      type: Object,
      default: null
    },
    candidate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    candidateInitials () {
      const splitedName = this.candidate.name.trim().split(' ')
      const firstInitial = splitedName[0][0]
      const lastInitial = splitedName[splitedName.length - 1][0]
      return firstInitial + lastInitial
    },
    isNewCandidate () {
      return this.applicant?.newCandidate
    },
    isUpdate () {
      return !this.isNewCandidate && this.applicant?.notificationCount
    }
  }
}
</script>

<style lang="scss">
.workflow-card {
  @include margin(bottom, 2);

  box-shadow: 0 2px 4px $gray100-8;

  &__body {
    align-items: flex-start;
    background: $white;
    border-radius: $base/2;
    cursor: pointer;
    display: flex;
    padding: 3*$base 3*$base;
  }

  &__avatar {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: $base*10;
  }

  &__picture-initials {
    @extend %button1;

    background-color: $gray20;
    color: $gray60;
    height: 100%;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  &__picture {
    align-items: center;
    background: $gray20;
    border-radius: 50%;
    display: flex;
    flex: 0 0 auto;
    height: $base*10;
    justify-content: center;
    overflow: hidden;
    width: $base*10;

    &-img {
      max-width: 100%;
      min-height: 100%;
    }
  }

  &__new-label {
    margin-top: -$base;

    &.ev-badge {
      padding: 2px;
    }
  }

  &__infos {
    margin-left: $base*3;
    overflow: hidden;
  }

  &__infos-name {
    @extend %base-typography;

    align-items: center;
    display: flex;
    margin-bottom: $base;
    margin-left: $base*3;
  }

  &__infos-job-title {
    @extend %caption;

    margin-left: $base*3;
  }

  &--notification &__infos-name { font-weight: bold; }

  &__infos-update {
    background: $red;
    border-radius: 50%;
    display: inline-block;
    height: 2*$base;
    margin-left: $base;
    width: 2*$base;
  }

  &--ghost {
    background: $white;
    border: 1px solid $blue-light;
    opacity: 0.5;
  }

  &--dragging { cursor: grabbing; }

  &__source-badge {
    @include margin(left, 3);
    @include margin(top, 2);
  }
}
</style>
