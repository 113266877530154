<template>
  <main class="workflow-board">
    <workflow-recommendation-stage
      data-test="recommendation-stage"
      @updated="updateFirstStage"
    />
    <section class="workflow-board__stages">
      <draggable
        v-bind="dragOptions"
        class="workflow-board__draggable"
        data-test="draggable-stages"
        handle=".workflow-stage__header"
        :list="stagesDup"
        @change="handleStageChange"
      >
        <workflow-stage
          v-for="(stage, index) in stagesDup"
          :key="stage.id"
          data-test="workflow-stage"
          :stage="stage"
          :position-id="positionId"
          :style="{ zIndex: (maxNumberOfStages - index) }"
          @added="handleAdd"
          @removed="handleRemove"
        />
      </draggable>
    </section>
    <section class="workflow-board__add-stage">
      <workflow-create-stage :stages-count="stages.length" />
    </section>
    <transition name="fade">
      <router-view />
    </transition>
    <move-candidate-modal
      v-if="showMoveCandidateModal"
      @accept="handleMoveCandidateAccept"
      @refuse="handleMoveCandidateRefuse"
      @close="handleMoveCandidateClose"
    />
  </main>
</template>

<script>
import draggable from 'vuedraggable'
import MoveCandidateModal from './components/MoveCandidateModal'
import WorkflowCreateStage from './components/WorkflowCreateStage'
import WorkflowStage from './components/WorkflowStage'
import WorkflowRecommendationStage from './components/WorkflowRecommendationStage.vue'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, FILTERS, COMPANY, STAGES } from '@/store/namespaces'

import { maxNumberOfStages } from '@/configs/constants'

const companyHelper = createNamespacedHelpers(COMPANY)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const filtersHelper = createNamespacedHelpers(FILTERS)
const stagesHelper = createNamespacedHelpers(STAGES)

const defaultMovingApplicant = () => {
  return {
    applicantId: undefined,
    fromStageId: undefined,
    toStageId: undefined
  }
}

export default {
  name: 'WorkflowBoard',
  components: {
    draggable,
    MoveCandidateModal,
    WorkflowCreateStage,
    WorkflowStage,
    WorkflowRecommendationStage
  },
  data () {
    return {
      maxNumberOfStages,
      movingApplicant: defaultMovingApplicant(),
      stagesDup: [],
      showMoveCandidateModal: false
    }
  },
  computed: {
    ...filtersHelper.mapState(['filter', 'employerFilter']),
    ...currentUserHelper.mapState(['currentUser']),
    ...companyHelper.mapGetters(['companyHasPreference']),
    ...stagesHelper.mapState(['stages']),
    positionId () {
      return this.$route.params.positionId
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'stages',
        chosenClass: 'workflow-stage--dragging',
        dragClass: 'workflow-stage--dragging',
        ghostClass: 'workflow-stage--ghost'
      }
    }
  },
  watch: {
    positionId: {
      immediate: true,
      handler (newPosition) {
        this.fetchStages()
      }
    },
    stages (newStages, oldStages) {
      if (newStages !== oldStages) {
        this.stagesDup = newStages
      }
    }
  },
  methods: {
    ...companyHelper.mapActions(['saveCompanyPreference']),
    ...stagesHelper.mapActions(
      ['getStages', 'getStage', 'moveApplicant', 'reorderStages', 'updateOrder']
    ),
    updateFirstStage () {
      const employerId = {
        all_candidates: null,
        my_candidates: this.currentUser?.id,
        candidates_from: this.employerFilter?.id
      }[this.filter]
      const firstStage = this.stages.find(s => s.stageIndex === 0)

      this.getStage({
        positionId: this.positionId,
        stageId: firstStage.id,
        employerId
      })
    },
    fetchStages () {
      this.getStages({
        positionId: this.positionId
      })
    },
    handleAdd (ev) {
      this.movingApplicant.applicantId = ev.id
      this.movingApplicant.currentState = ev.currentState
      this.movingApplicant.toStageId = ev.stageId
      this.movingApplicant.toStageOrder = ev.applicantsOrder
      this.tryMove()
    },
    handleStageChange (ev) {
      const stagesList = this.stagesDup.map((stage, index) => ({
        ...stage,
        stageIndex: index + 1
      }))

      this.reorderStages({ positionId: this.positionId, stagesList })
        .catch(_er => {
          this.stagesDup = this.stages
            .sort((a, b) => a.stageIndex - b.stageIndex)
        })
    },
    handleRemove (ev) {
      this.movingApplicant.applicantId = ev.id
      this.movingApplicant.currentState = ev.currentState
      this.movingApplicant.fromStageId = ev.stageId
      this.movingApplicant.fromStageOrder = ev.applicantsOrder
      this.tryMove()
    },
    async tryMove () {
      const applicant = this.movingApplicant
      if (
        applicant.applicantId &&
        applicant.fromStageId &&
        applicant.toStageId
      ) {
        if (this.openRefuseModal(applicant)) {
          this.openApplicant('ApplicantRejected', {
            applicantId: applicant.applicantId,
            action: 'refuse'
          })
        } else {
          this.movingApplicant = defaultMovingApplicant()
          await this.moveApplicant({
            applicantId: applicant.applicantId,
            fromStageId: applicant.fromStageId,
            currentState: applicant.currentState,
            toStageId: applicant.toStageId
          })
          this.$smartlook('track', 'moved_candidate')
          this.$gtmTrackEvent('moved_candidate')
          this.updateOrder({
            stageId: applicant.fromStageId,
            applicantsOrder: applicant.fromStageOrder
          })
          this.updateOrder({
            stageId: applicant.toStageId,
            applicantsOrder: applicant.toStageOrder
          })
        }
        if (this.openMoveCandidateModal(applicant)) {
          this.$smartlook('track', 'move_candidate_automatically_opened')
          this.showMoveCandidateModal = true
        }
      }
    },
    findStage (stageId) {
      return this.stages.find(
        (stage) => stage.id === stageId
      )
    },
    openRefuseModal (applicant) {
      return this.findStage(applicant.toStageId).kind === 'refused' &&
        !['company_rejected', 'applicant_refused']
          .includes(applicant.currentState)
    },
    openApplicant (routeName, params) {
      this.$router.push({ name: routeName, params })
    },
    openMoveCandidateModal (applicant) {
      const hasPreference = this.companyHasPreference('moveCandidateAutomatically')
      const fromStage = this.findStage(applicant.fromStageId)
      const toStage = this.findStage(applicant.toStageId)

      return fromStage.kind === 'screened' && fromStage.default &&
             toStage.kind === 'interview' && toStage.default &&
             !hasPreference
    },
    handleMoveCandidateAccept () {
      this.saveCompanyPreference({ moveCandidateAutomatically: true })
      this.handleMoveCandidateClose()
    },
    handleMoveCandidateRefuse () {
      this.saveCompanyPreference({ moveCandidateAutomatically: false })
      this.handleMoveCandidateClose()
    },
    handleMoveCandidateClose () {
      this.showMoveCandidateModal = false
    }
  }
}
</script>

<style lang="scss">
.workflow-board {
  display: grid;
  flex-grow: 1;
  grid-template-columns: repeat(3, auto);
  padding-bottom: $base*10;
  scroll-behavior: smooth;

  &__add-stage {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: $base*75;
  }

  &__draggable {
    display: flex;
    height: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
