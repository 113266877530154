<template>
  <div v-if="showStageTitle">
    <h4
      data-test="workflow-stage-title__name"
      class="workflow-stage-title__name"
      @click="startEditStageTitle"
    >
      {{ title }} - {{ count }}
    </h4>
    <span
      v-if="showTooltip"
      data-test="workflow-stage-title__tooltip"
      class="workflow-stage-title__tooltip"
    >
      {{ $t(`${langPath}.tooltipText`) }}
    </span>
  </div>
  <app-text-input
    v-else
    ref="editStageInput"
    v-model="value"
    data-test="workflow-stage-title__input"
    class="workflow-stage-title__input"
    name="editStageInput"
    @blur="stopEditStageTitle"
    @keydown.esc="stopEditStageTitle"
    @keydown.enter="handleSubmitStageTitle"
  />
</template>

<script>
export default {
  name: 'WorkflowStageTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      showTooltip: false,
      showStageTitle: true,
      value: this.title,
      langPath: __langpath
    }
  },
  watch: {
    title (newValue) {
      this.value = newValue
    }
  },
  methods: {
    startEditStageTitle () {
      if (!this.editable) {
        this.showTooltip = true
        setTimeout(() => { this.showTooltip = false }, 2500)
        return
      }

      this.showStageTitle = false
      this.$nextTick(() => {
        this.$refs.editStageInput.$el.querySelector('input').focus()
      })
    },
    stopEditStageTitle () {
      this.showStageTitle = true
      this.value = this.title
    },
    handleSubmitStageTitle () {
      if (!this.value) {
        return this.stopEditStageTitle()
      }

      this.$emit('update-stage-label', this.value)
      this.showStageTitle = true
    }
  }
}
</script>

<style lang="scss">
.workflow-stage-title {
  &__name {
    @extend %bold;
    @extend %body-text2;

    display: inline-block;
    padding: $base*4 0;
    text-transform: uppercase;

    &:hover {
      @include hover-link($blue-light, 1);

      cursor: pointer;
    }
  }

  &__input {
    margin-top: -$base;
  }

  &__tooltip {
    @extend %caption;

    background-color: $gray100;
    background-color: rgba(35, 35, 35, 0.9);
    border-radius: 2px;
    color: $gray5;
    left: 0;
    padding: 5px 2px;
    position: absolute;
    text-align: center;
    top: 45px;
    width: 190px;
    z-index: 11;

    &::after {
      border-bottom: 5px solid $gray100;
      border-bottom: 5px solid rgba(35, 35, 35, 0.9);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      bottom: 100%;
      content: ' ';
      font-size: 0;
      left: 10%;
      line-height: 0;
      margin-left: -5px;
      position: absolute;
      width: 0;
    }
  }
}
</style>
