<template>
  <workflow-stage-skeleton v-if="isStageLoading" />
  <section
    v-else-if="isRecommendationVisible"
    class="workflow-recommendation-stage"
    data-test="workflow-recommendation-stage"
  >
    <header class="workflow-recommendation-stage__header">
      <workflow-stage-title
        class="workflow-recommendation-stage__header-title"
        :title="stageTitle"
        :count="suggestedCandidatesCount"
        :editable="false"
        data-test="title"
      />
    </header>

    <div>
      <workflow-stage-card
        v-for="candidate in candidatesList"
        :key="candidate.id"
        :candidate="candidate"
        :data-ga-id="clickedCardEvent"
        data-test="card"
        @click="openProfile(candidate)"
      />
    </div>

    <workflow-add-to-board-modal
      v-if="modalOpen"
      :candidate="selectedCandidate"
      data-test="add-to-board-modal"
      @close="modalOpen = !modalOpen"
      @updated="handleModalUpdated"
    />

    <div
      v-if="showRecommendationButton"
      data-test="recommendation-button"
    >
      <p
        class="workflow-recommendation-stage__description"
        data-test="recommendation-button-body"
      >
        {{ $t(`${langPath}.body`) }}
      </p>
      <ev-link
        class="workflow-recommendation-stage__button"
        data-test="recommendation-button-link"
        :a-href="marketPlaceRoute"
      >
        {{ $t(`${langPath}.seeCandidates`) }}
      </ev-link>
    </div>
  </section>
</template>

<script>
import EvLink from '@revelotech/everestV2/EvLink'
import WorkflowStageCard from './WorkflowStageCard'
import WorkflowStageSkeleton from '@/components/Skeletons/WorkflowStageSkeleton'
import WorkflowStageTitle from './WorkflowStageTitle'
import WorkflowAddToBoardModal from './WorkflowAddToBoardModal'
import huntersApplicants from '@/repository/hunters-app/applicants'
import suggestedCandidates from '@/repository/companies-app/suggested-candidates'
import positionsRepository from '@/repository/companies-app/positions'
import recommendationLimit from '@/helpers/constants/recommendation-limit'
import gtmEvents from '@/helpers/constants/gtm-events'

import { createNamespacedHelpers } from 'vuex'
import { POSITIONS } from '@/store/namespaces'

import { companiesUrl } from '@/helpers/constants/external-urls'

const positionHelper = createNamespacedHelpers(POSITIONS)

export default {
  name: 'WorkflowRecommendationStage',
  components: {
    EvLink,
    WorkflowAddToBoardModal,
    WorkflowStageTitle,
    WorkflowStageCard,
    WorkflowStageSkeleton
  },
  data () {
    return {
      companiesPosition: {},
      candidates: [],
      modalOpen: false,
      selectedCandidate: {},
      isStageLoading: true,
      langPath: __langpath,
      recommendationSource: ''
    }
  },
  computed: {
    suggestionsCount () {
      return recommendationLimit.suggestionsLimit
    },
    ...positionHelper.mapState(['positions']),
    isRecommendationVisible () {
      return this.candidatesList.length
    },
    showRecommendationButton () {
      const isMarketplaceSuggestion = this.recommendationSource === 'ats_recommendation_by_position'
      const isPositionPresent = Object.keys(this.companiesPosition).length

      return isMarketplaceSuggestion && isPositionPresent
    },
    clickedCardEvent () {
      return gtmEvents.clickedOnCandidateRecommended
    },
    count () {
      return this.candidatesList.length
    },
    suggestedCandidatesCount () {
      if (this.recommendationSource === 'hunters') return this.count

      return (this.count > 0 && this.count < this.suggestionsCount)
        ? this.count
        : this.suggestionsCount
    },
    candidatesList () {
      return this.candidates.map((candidate) => ({
        ...candidate,
        name: candidate.name,
        avatar: candidate.avatarUrl,
        slug: candidate.slug,
        jobTitle: candidate.lastExperienceTitle
      }))
    },
    marketPlaceRoute () {
      return `${companiesUrl}${this.companiesPosition.searchPageHref}`
    },
    positionId () {
      return this.$route.params.positionId
    },
    stageTitle () {
      return this.recommendationSource === 'hunters'
        ? this.$t(`${this.langPath}.titleHunters`)
        : this.$t(`${this.langPath}.titleMarketplace`)
    }
  },
  watch: {
    positionId: {
      immediate: true,
      handler () {
        this.candidates = []
        this.isStageLoading = true
        this.setCompaniesPosition()
      }
    },
    companiesPosition: {
      immediate: true,
      async handler () {
        await this.getCandidates()
        this.isStageLoading = false
      }
    }
  },
  methods: {
    async getCandidates () {
      const sources = [
        this.getHuntersApplicants,
        this.getSuggestedCandidates
      ]

      for (const getCandidatesFromSource of sources) {
        await getCandidatesFromSource()

        if (this.candidates.length > 0) break
      }

      if (this.count > 0) {
        this.$smartlook('track', 'accessed_workflow_with_recommendation', {
          external_position_id: this.companiesPosition.id
        })
      }
    },
    async setCompaniesPosition () {
      const filteredPosition = this
        .positions
        .flat()
        .find(item =>
          parseInt(item.id) === parseInt(this.positionId)
        )
      if (!filteredPosition) return

      const companiesPositions = await positionsRepository
        .getPositions([filteredPosition.externalId])

      this.companiesPosition = companiesPositions.length
        ? companiesPositions[0]
        : { id: filteredPosition.externalId }
    },
    async getHuntersApplicants () {
      try {
        this.candidates = await huntersApplicants.getApplicants({
          externalPositionId: this.companiesPosition.id
        })
      } catch (error) {
        this.$rollbar.error(error)
      } finally {
        this.recommendationSource = 'hunters'
      }
    },
    async getSuggestedCandidates () {
      const limit = this.suggestionsCount

      try {
        this.candidates = await suggestedCandidates.getSuggestedCandidates(
          this.companiesPosition.id, limit
        )
      } catch (error) {
        this.$rollbar.error(error)
      } finally {
        this.recommendationSource = 'ats_recommendation_by_position'
      }
    },
    openCandidateProfile (candidate) {
      const { href } = this.$router.resolve({
        name: 'CandidateProfile',
        params: { id: candidate.slug },
        query: { source: this.recommendationSource }
      })

      window.open(href, '_blank')
    },
    openAddToBoardModal (candidate) {
      this.selectedCandidate = candidate
      this.modalOpen = true
    },
    openProfile (candidate) {
      this.recommendationSource === 'hunters'
        ? this.openAddToBoardModal(candidate)
        : this.openCandidateProfile(candidate)
    },
    handleModalUpdated (candidateId) {
      this.modalOpen = !this.modalOpen
      this.candidates = this.candidates.filter(c => c.id !== candidateId)
      this.$emit('updated')
    }
  }
}
</script>

<style lang="scss">
.workflow-recommendation-stage {
  align-self: stretch;
  background: $gray20;
  border-radius: $base;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 $base*2;
  min-width: 300px;
  overflow: visible;
  padding: 0 $base*2 $base*4;
  width: $base*75;

  &:first-child { margin-left: 0; }

  &__description {
    @extend %body-text1;
    @extend %antenna;

    margin-top: $base * 5;
    text-align: center;
  }

  &__button {
    margin: $base * 4 auto;
  }

  &__header {
    align-items: center;
    background: $gray20;
    display: flex;
    justify-content: flex-end;
    padding: $base 0 $base;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;

    &-title {
      flex-grow: 1;
    }

    &:hover { cursor: grab; }
  }

  &--ghost { opacity: 0.4; }

  img {
    width: 100%;
  }
}
</style>
