import config from '@/configs/stages'

export const stageRoute = (kind) =>
  config[kind]?.route || config.default.route

export const showInvitePending = (currentState) =>
  config.default.pendingState === currentState

export const showInviteRefused = (currentState) =>
  config.default.refusedState === currentState

export const showTab = (kind, tab) => config[kind]?.tabs.includes(tab)

export const applicantRoute = (currentState, kind) =>
  currentState === 'company_rejected' ? 'ApplicantRejected' : stageRoute(kind)
