<template>
  <ev-modal
    class="add-to-board-modal"
    size="md"
    @close="$emit('close')"
  >
    <h1 class="add-to-board-modal__title" data-test="name">
      {{ candidate.name }}
    </h1>
    <p class="add-to-board-modal__experience">
      <span data-test="last-experience">{{ candidate.lastExperienceTitle }}</span>
      <span
        v-if="candidate.currentCompany"
        class="add-to-board-modal__company"
        data-test="company"
      >
        {{ candidate.currentCompany }}
      </span>
    </p>

    <div class="add-to-board-modal__section">
      <p v-if="candidate.city" data-test="city">
        <span data-test="candidate-city-name">
          {{ candidate.city }}
        </span>
        <ev-icon
          color="gray40"
          size="small"
          name="map-marker"
        />
      </p>

      <p class="add-to-board-modal__salaries">
        <span
          v-if="candidate.currentSalary"
          class="add-to-board-modal__salary"
          data-test="current-salary"
        >
          {{ $t(`${langPath}.currentSalary`, { salary: candidate.currentSalary }) }}
        </span>
        <span
          class="add-to-board-modal__salary"
          data-test="desired-salary"
        >
          {{ $t(`${langPath}.desiredSalary`, { salary: candidate.desiredSalary }) }}
        </span>
      </p>
    </div>

    <div
      v-for="field in candidate.customFields"
      :key="field.title"
      data-test="custom-field"
    >
      <hr class="add-to-board-modal__separator">

      <div class="add-to-board-modal__section">
        <h2 class="add-to-board-modal__section-title">
          {{ field.title }}
        </h2>
        <p class="add-to-board-modal__section-body" data-test="comments">
          {{ field.value }}
        </p>
      </div>
    </div>

    <hr class="add-to-board-modal__separator">

    <div class="add-to-board-modal__section">
      <div class="add-to-board-modal__contact">
        <ev-icon name="file-alt" size="small" />
        <a
          class="add-to-board-modal__contact-info"
          target="_blank"
          :href="candidate.resumeLink"
          data-test="resume"
        >
          {{ $t(`${langPath}.resume`, { name: candidate.name }) }}
        </a>
      </div>
      <div class="add-to-board-modal__contact">
        <ev-icon name="linkedin" size="small" />
        <a
          class="add-to-board-modal__contact-info"
          target="_blank"
          :href="candidate.linkedinUrl"
          data-test="linkedin"
        >
          {{ candidate.linkedinUrl }}
        </a>
      </div>
    </div>

    <template #footer>
      <div class="add-to-board-modal__footer">
        <ev-button
          class="add-to-board-modal__button"
          variant="secondary"
          color="blue"
          data-test="reject-button"
          @click="handleReject"
        >
          {{ $t(`${langPath}.reject`) }}
        </ev-button>
        <ev-button
          class="add-to-board-modal__button"
          variant="primary"
          data-test="approve-button"
          @click="handleApprove"
        >
          {{ $t(`${langPath}.approve`) }}
        </ev-button>
      </div>
    </template>
  </ev-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvModal from '@revelotech/everestV2/EvModal'
import repository from '@/repository/hunters-app/applicants'
import { TheNotificationCardList } from '@revelotech/everest'

export default {
  name: 'WorkflowAddToBoardModal',

  components: {
    EvButton,
    EvIcon,
    EvModal
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  methods: {
    async handleApprove () {
      try {
        await repository.approveApplicant(this.candidate)
        this.$emit('updated', this.candidate.id)
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.successAdded`),
          modifier: 'success'
        })
      } catch {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.genericError`),
          modifier: 'error'
        })
      }
    },

    async handleReject () {
      try {
        await repository.rejectApplicant(this.candidate.id)
        this.$emit('updated', this.candidate.id)
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.successRejected`),
          modifier: 'success'
        })
      } catch {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.genericError`),
          modifier: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-to-board-modal {
  &__title {
    @extend %h4;
  }

  &__experience {
    @extend %body-text2;

    color: $gray60;
    margin-bottom: 24px;
    margin-top: 8px;
  }

  &__company {
    &::before {
      content: "●";
      margin-left: 12px;
      margin-right: 8px;
    }
  }

  &__separator {
    margin: 20px 0;
  }

  &__section {
    @extend %body-text2;

    &-title {
      display: flex;
      font-weight: 600;
      justify-content: space-between;
    }

    &-body {
      color: $gray60;
      margin-top: 12px;
    }
  }

  &__salaries {
    margin-top: 8px;
  }

  &__salary + &__salary {
    &::before {
      content: "●";
      font-size: 0.7rem;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &__contact {
    align-items: center;
    display: flex;

    &-info {
      color: $blue-light;
      margin-left: 12px;
      overflow-wrap: anywhere;
      overflow-wrap: break-word;
      text-decoration: underline;
    }
  }

  &__contact + &__contact {
    margin-top: 12px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  &__button + &__button {
    margin-left: 16px;
  }
}
</style>
